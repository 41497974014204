import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";
import api from "../api";
import * as connectionActions from "../app/connection";
import { AppThunkAction } from "../../redux-store";
import { driverT } from "dora-contracts";

export type Me = t.TypeOf<typeof driverT>;

export const logoutSession = createAsyncThunk(
  "/auth/session-logout",
  async () => {
    // TODO, Handle logged in route in session storage
    const result = await fetch("/drivers-api/sessions/logout", {
      method: "POST",
    });
    if (!result.ok) {
      throw new Error();
    }
  }
);

const doInit = createAsyncThunk<
  { driverId: string; orgId: string },
  void,
  { rejectValue: AuthStatus }
>("/auth/init", async (_, { dispatch, rejectWithValue }) => {
  return await api({ dispatch }).get({
    url: "/drivers-api/auth/me",
    decoder: async (response: Response) => {
      if (response.status === 401) {
        return rejectWithValue("UNAUTHENTICATED");
      }
      return response.json().then(tPromise.decode(driverT));
    },
  });
});

const getAuthMode = createAsyncThunk(
  "/auth/get-mode",
  async (_, { dispatch }) => {
    const mode = await api({ dispatch }).get({
      url: "/drivers-api/auth/mode",
      decoder: (response) =>
        response.json().then(
          tPromise.decode(
            t.strict({
              mode: t.string,
            })
          )
        ),
    });
    return mode;
  }
);

export const init = (): AppThunkAction => async (dispatch, getState) => {
  if (getState().auth.me) {
    return;
  }
  await Promise.all([dispatch(getAuthMode()), dispatch(doInit())]);
};

export const login = createAsyncThunk(
  "/auth/login",
  async (input: { orgId?: string; number: string; pin: string }, thunkApi) => {
    const result = await api(thunkApi).post({
      url: "/drivers-api/auth",
      body: input,
      decoder: async (response: Response) => {
        if (response.ok) {
          return response.json().then(tPromise.decode(driverT));
        }
        if (response.status === 401) {
          return null;
        }
        thunkApi.dispatch(connectionActions.errorReturned() as any);
        throw new Error();
      },
    });
    await thunkApi.dispatch(getAuthMode());
    return result;
  }
);

export const logout = createAsyncThunk(
  "/auth/logout",
  async (_: void, thunkApi) => {
    await api(thunkApi)
      .delete({
        url: "/drivers-api/auth/session",
        decoder: () => Promise.resolve(),
      })
      .finally(() => {
        thunkApi.dispatch(clearState());
      });
  }
);

type AuthStatus =
  | "UNINITIALIZED"
  | "INITIALIZING"
  | "AUTHENTICATED"
  | "UNAUTHENTICATED"
  | "AUTH_ERROR";

interface State {
  status: AuthStatus;
  me?: Me;
  authMode?: string;
}

const initialState: State = { status: "UNINITIALIZED" };

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSucceeded: (state, action: PayloadAction<Me>) => {
      state.status = "AUTHENTICATED";
      state.me = action.payload;
    },
    clearState: (state) => {
      if (state.status === "AUTHENTICATED") {
        return initialState;
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doInit.pending, (state) => {
        state.status = "INITIALIZING";
        state.me = undefined;
      })
      .addCase(doInit.fulfilled, (state, action) => {
        state.status = "AUTHENTICATED";
        state.me = action.payload as Me;
      })
      .addCase(doInit.rejected, (state, action) => {
        state.status = action.payload || "AUTH_ERROR";
        state.me = undefined;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.status = "AUTHENTICATED";
        state.me = action.payload;
      })
      .addCase(getAuthMode.fulfilled, (state, action) => {
        state.authMode = action.payload.mode;
      })
      .addCase(logoutSession.fulfilled, (state) => {
        if (state.me) {
          delete state.me.sessionId;
        }
      });
  },
});

export default slice.reducer;
export const { clearState, loginSucceeded } = slice.actions;
