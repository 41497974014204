import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import * as actions from "../../ducks/auth";
import { useAppDispatch } from "../../redux-store";
import { useTranslation } from "react-i18next";

type InputEvent = React.ChangeEvent<HTMLInputElement>;

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("authPage");
  const [searchParams] = useSearchParams();
  const [number, setNumber] = useState("");
  const [loginError, setLoginError] = useState<
    null | "AUTH_ERROR" | "SERVER_ERROR"
  >(null);
  const [pin, setPin] = useState("");
  const changeNumber = (e: InputEvent) => {
    setNumber(e.target.value);
  };
  const changePin = (e: InputEvent) => {
    setPin(e.target.value);
  };
  const orgId = searchParams.get("org") || undefined;
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(actions.login({ orgId, number, pin }))
      .unwrap()
      .then(
        (result) => {
          if (result) {
            const state = location.state as any;
            console.log({ state });
            if (state && state?.returnPath) {
              navigate({
                pathname: state && state?.returnPath,
              });
            } else {
              navigate("/routes/login");
            }
          } else {
            setLoginError("AUTH_ERROR");
          }
        },
        () => setLoginError("SERVER_ERROR")
      );
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        mt={14}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} />
        <Typography component="h1" variant="h5">
          {t("login.heading")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 4,
            }}
            id="drivers_number"
            label={t("login.textFieldLabel_DRIVER")}
            name="drivers_number"
            autoComplete="drivers_number"
            value={number}
            onChange={changeNumber}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 4,
            }}
            name="password"
            label={t("login.textFieldLabel_PIN")}
            value={pin}
            onChange={changePin}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {loginError && (
            <Alert severity="error">
              {loginError === "AUTH_ERROR" ? (
                <span>{t("login.errorMessage")}</span>
              ) : (
                <span>{t("login.errorMessage_GENERAL")}</span>
              )}
            </Alert>
          )}
          {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Husk mig"
                    /> */}
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("login.loginButtonLabel")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
