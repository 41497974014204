import { useEffect } from "react";
import Alert from "@mui/material/Alert";
import * as authActions from "../ducks/auth";
import {
  selectMe,
  selectAuthMode,
  selectStatus,
} from "../ducks/auth/selectors";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useAppDispatch } from "../redux-store";

interface MeProps {
  me: authActions.Me;
}

function withAuth<T>(
  Component: React.ComponentType<T & MeProps>
): React.ComponentType<T> {
  return (props: T) => {
    const dispatch = useAppDispatch();
    const state = useSelector(selectStatus);
    useEffect(() => {
      if (state === "UNINITIALIZED") {
        dispatch(authActions.init());
      }
    }, [state, dispatch]);
    const me = useSelector(selectMe);
    const authMode = useSelector(selectAuthMode);
    const location = useLocation();
    if (!authMode) {
      return null;
    }
    switch (state) {
      case "UNINITIALIZED":
      case "INITIALIZING":
        return null;
      case "AUTHENTICATED":
        return <Component {...props} me={me!} />;
      case "UNAUTHENTICATED":
        return (
          <Navigate
            to="/login"
            state={{
              returnPath: location.pathname,
              returnQuery: location.search,
            }}
          />
        );
      case "AUTH_ERROR":
        return <p>"Der skete en fej under authenticeringen"</p>;
    }
  };
}

export default withAuth;
