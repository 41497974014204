import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "../../../redux-store";

const prefix = "app/version";

type State = {
  initialVersion: string;
  outdated: boolean;
};

const initialState: State = {
  initialVersion: "",
  outdated: false,
};

export const initialize =
  (initialVersion: string): AppThunkAction =>
  (dispatch) => {
    dispatch(slice.actions.setInitialVersion(initialVersion));
    setInterval(async () => {
      const response = await fetch("/version/ui-version");
      if (!response.ok) {
        return;
      }
      const newVersion = await response.text();
      dispatch(slice.actions.setNewVersion(newVersion));
    }, 5000);
  };

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {
    setInitialVersion: (state, action: PayloadAction<string>) => {
      state.initialVersion = action.payload;
      state.outdated = false;
    },
    setNewVersion: (state, action: PayloadAction<string>) => {
      if (action.payload === "NO_CONTENT") {
        return;
      }
      state.outdated = state.initialVersion !== action.payload;
    },
  },
});

export default slice.reducer;
