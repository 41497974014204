import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  sessionT,
  sessionsT,
  sessionResponseT,
  addEventT,
  Stop,
  RouteStop,
  Cargo,
  Client,
  Attachment,
  Session,
  StopDocument,
  documentsT,
} from "./types";
import keyBy from "lodash/keyBy";
import groupBy from "lodash/groupBy";
import values from "lodash/values";
import mapValues from "lodash/mapValues";
import api, { createDecoder, noContentDecoder } from "../../api";
import type { Dictionary } from "lodash";
import Decimal from "decimal.js-light";
import { DateTime } from "luxon";
import { getPosition } from "../../../helpers/position";
import { reverseGeoCode } from "../../../helpers/google-helpers";
import { AppThunkAction, RootState } from "../../../redux-store";
import { selectCurrentSessionId } from "./selectors";

const prefix = "/app/sessions";

interface SessionStops {
  stopIds: string[];
  routeStops: RouteStop[];
}

export type State = {
  stops: Dictionary<Stop>;
  routeStops: RouteStop[];
  sessionStops: { [key: string]: SessionStops };
  sessions: Dictionary<Session>;
  clients: Record<string, Client>;
  cargos: Record<string, Cargo>;
  currentSessionId: string | null;
  attachments: Dictionary<Attachment>;
  attachmentsByCargoId: Dictionary<string[]>;
  stopsDocuments: Record<string, StopDocument[]>;
  createdByDispatcher: boolean | null;
  showCompletedStops: boolean;
};

const initialState: State = {
  stops: {},
  routeStops: [],
  sessionStops: {},
  sessions: {},
  cargos: {},
  clients: {},
  currentSessionId: null,
  attachments: {},
  attachmentsByCargoId: {},
  stopsDocuments: {},
  createdByDispatcher: null,
  showCompletedStops: false,
};

const loadOtherSessions = createAsyncThunk(
  `${prefix}/load-others`,
  async (id: string, thunkApi) => {
    return await api(thunkApi).get({
      url: `/drivers-api/sessions?sid=${id}`,
      decoder: createDecoder(sessionsT),
    });
  }
);

export const loadSession = createAsyncThunk(
  `${prefix}/load`,
  async (id: string, thunkApi) => {
    thunkApi.dispatch(slice.actions.setSession(id));
    await thunkApi.dispatch(loadOtherSessions(id)).unwrap();
    return await api(thunkApi).get({
      url: `/drivers-api/sessions/${id}`,
      decoder: createDecoder(sessionResponseT),
    });
  }
);

export const reloadCurrentSession = createAsyncThunk(
  `${prefix}/reload`,
  async (_, thunkApi) => {
    const sessionId = selectCurrentSessionId(thunkApi.getState() as RootState);
    if (sessionId) {
      return thunkApi.dispatch(loadSession(sessionId)).unwrap();
    } else {
      throw new Error("No current session");
    }
  }
);

export const addComment = createAsyncThunk(
  `${prefix}/add-comment`,
  async (
    input: {
      sessionId: string;
      cargoId: string;
      stopId: string;
      comment: string;
    },
    thunkApi
  ) => {
    const { sessionId, cargoId, stopId, comment } = input;
    const position = await getPosition();
    const data = await api(thunkApi).post({
      url: `/drivers-api/sessions/${sessionId}/events`,
      body: {
        cargoId,
        stopId,
        comment,
        position,
        type: "ADD_COMMENT",
        time: DateTime.now().toISO(),
      },
      decoder: createDecoder(sessionT),
    });
    thunkApi.dispatch(slice.actions.updateSession(data));
  }
);

export const addInvoiceReference = createAsyncThunk(
  `${prefix}/add-invoice-reference`,
  async (
    input: {
      sessionId: string;
      cargoId: string;
      stopId: string;
      ref: string;
    },
    thunkApi
  ) => {
    const { sessionId, cargoId, stopId, ref } = input;
    const position = await getPosition();
    const data = await api(thunkApi).post({
      url: `/drivers-api/sessions/${sessionId}/events`,
      body: {
        cargoId,
        stopId,
        ref,
        position,
        type: "ADD_INVOICE_REFERENCE",
        time: DateTime.now().toISO(),
      },
      decoder: createDecoder(sessionT),
    });
    thunkApi.dispatch(slice.actions.updateSession(data));
  }
);

export const addCubicMeters = createAsyncThunk(
  `${prefix}/add-cubic-meters`,
  async (
    input: {
      sessionId: string;
      cargoId: string;
      stopId: string;
      cubicMeters: Decimal;
    },
    thunkApi
  ) => {
    const { sessionId, cargoId, stopId, cubicMeters } = input;
    const position = await getPosition();
    const session = await api(thunkApi).post({
      url: `/drivers-api/sessions/${sessionId}/events`,
      body: {
        cargoId,
        stopId,
        cubicMeters: cubicMeters.toString(),
        position,
        type: "ADD_VOLUME",
        time: DateTime.now().toISO(),
      },
      decoder: createDecoder(sessionT),
    });
    thunkApi.dispatch(slice.actions.updateSession(session));
  }
);

export const addWeight = createAsyncThunk(
  `${prefix}/add-weight`,
  async (
    input: {
      sessionId: string;
      cargoId: string;
      stopId: string;
      weight: Decimal;
    },
    thunkApi
  ) => {
    const { sessionId, cargoId, stopId, weight } = input;
    const position = await getPosition();
    const session = await api(thunkApi).post({
      url: `/drivers-api/sessions/${sessionId}/events`,
      body: {
        cargoId,
        stopId,
        weight: weight.toString(),
        position,
        type: "ADD_WEIGHT",
        time: DateTime.now().toISO(),
      },
      decoder: createDecoder(sessionT),
    });
    thunkApi.dispatch(slice.actions.updateSession(session));
  }
);

export const startTrip = createAsyncThunk(
  `${prefix}/start-trip`,
  async (
    input: {
      sessionId: string;
      odometer: number;
      dateTime: DateTime | null;
      note: string;
    },
    thunkApi
  ) => {
    const { sessionId, odometer, note, dateTime } = input;
    const position = await getPosition();
    const session = await api(thunkApi).post({
      url: `/drivers-api/sessions/${sessionId}/events`,
      body: {
        sessionId,
        odometer,
        position,
        note,
        type: "START_TRIP",
        time: dateTime?.toISO() || DateTime.now().toISO(),
      },
      decoder: createDecoder(sessionT),
    });
    thunkApi.dispatch(slice.actions.updateSession(session));
  }
);

export const stopTrip = createAsyncThunk(
  `${prefix}/stop-trip`,
  async (
    input: {
      sessionId: string;
      odometer: number;
      note: string;
      dateTime: DateTime | null;
    },
    thunkApi
  ) => {
    const { sessionId, odometer, note, dateTime } = input;
    const position = await getPosition();
    const session = await api(thunkApi).post({
      url: `/drivers-api/sessions/${sessionId}/events`,
      body: {
        sessionId,
        odometer,
        position,
        note,
        type: "STOP_TRIP",
        time: dateTime?.toISO() || DateTime.now().toISO(),
      },
      decoder: createDecoder(sessionT),
    });
    thunkApi.dispatch(slice.actions.updateSession(session));
  }
);

const completeStop = createAsyncThunk(
  `${prefix}/completeStop`,
  async (
    id: {
      type: "PICKUP" | "DROPOFF";
      sessionId: string;
      cargoId: string;
      stopId: string;
      time?: DateTime;
    },
    thunkApi
  ) => {
    const { cargoId, stopId, sessionId, time } = id;
    const position = await getPosition();
    const place = position && (await reverseGeoCode(position));
    const result = place && (await place.lookup());
    return api(thunkApi).post({
      url: `/drivers-api/sessions/${sessionId}/events`,
      body: addEventT.encode({
        type: id.type,
        cargoId,
        stopId,
        position: position && {
          ...position,
          place: result ? { ...result, country: result.countryCode } : null,
        },
        time: time || DateTime.now(),
      }),
      decoder: createDecoder(sessionT),
    });
  }
);

export const pickup = (id: {
  sessionId: string;
  cargoId: string;
  stopId: string;
  time?: DateTime;
}) => completeStop({ ...id, type: "PICKUP" });

export const dropoff = (id: {
  sessionId: string;
  cargoId: string;
  stopId: string;
  time?: DateTime;
}) => completeStop({ ...id, type: "DROPOFF" });

const driverArrivedAtDestinationInner = createAsyncThunk(
  `${prefix}/driver-arrived-at-destination`,
  async (
    id: { sessionId: string; stopId: string; cargoId: string; time?: DateTime },
    thunkApi
  ) => {
    const { sessionId, stopId, cargoId, time } = id;
    const position = await getPosition();
    const session = await api(thunkApi).post({
      url: `/drivers-api/sessions/${sessionId}/events`,
      body: {
        cargoId,
        stopId,
        position,
        type: "ARRIVED",
        time: (time || DateTime.now()).toISO(),
      },
      decoder: createDecoder(sessionT),
    });
    thunkApi.dispatch(slice.actions.updateSession(session));
  }
);

export const driverArrivedAtDestination =
  (input: {
    sessionId: string;
    stopId: string;
    cargoId: string;
    time?: DateTime;
  }): AppThunkAction<Promise<void>> =>
  (dispatch) =>
    dispatch(driverArrivedAtDestinationInner(input)).unwrap();

const driverDepartedFromDestinationInner = createAsyncThunk(
  `${prefix}/driver-departed-from-destination`,
  async (
    id: { sessionId: string; stopId: string; cargoId: string; time?: DateTime },
    thunkApi
  ) => {
    const { sessionId, stopId, cargoId, time } = id;
    const position = await getPosition();
    const session = await api(thunkApi).post({
      url: `/drivers-api/sessions/${sessionId}/events`,
      body: {
        stopId,
        cargoId,
        position,
        type: "DEPARTED",
        time: (time || DateTime.now()).toISO(),
      },
      decoder: createDecoder(sessionT),
    });
    thunkApi.dispatch(slice.actions.updateSession(session));
  }
);

export const driverDepartedFromDestination =
  (input: {
    sessionId: string;
    stopId: string;
    cargoId: string;
    time?: DateTime;
  }): AppThunkAction<Promise<void>> =>
  (dispatch) =>
    dispatch(driverDepartedFromDestinationInner(input)).unwrap();

export const uploadFile2 = createAsyncThunk(
  `${prefix}/add-file-2`,
  async (input: { sessionId: string; formData: FormData }, thunkApi) => {
    const position = await getPosition();
    if (position) {
      input.formData.append("pos_lat", position.lat.toString());
      input.formData.append("pos_lon", position.lon.toString());
      input.formData.append("pos_accuracy", position.accuracy.toString());
    }
    input.formData.append("time", DateTime.now().toISO());
    const result = await fetch(
      `/drivers-api/sessions/${input.sessionId}/files2`,
      {
        method: "POST",
        body: input.formData,
      }
    );
    if (!result.ok) {
      throw new Error("BAD RESULT");
    }
    {
      const data = await result.json();
      const session = sessionT.decode(data);
      if (session._tag === "Right") {
        thunkApi.dispatch(slice.actions.updateSession(session.right));
      } else {
        console.error({ session, data });
        throw new Error("Bad session");
      }
    }
  }
);

export const loadStopDocuments = createAsyncThunk(
  `${prefix}/load-stop-documents`,
  async (input: { stopId: string }, thunkApi) => {
    const { stopId } = input;
    return await api(thunkApi).get({
      url: `/drivers-api/stops/${stopId}/documents`,
      decoder: createDecoder(documentsT),
    });
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setShowCompletedStops: (state, action: PayloadAction<boolean>) => {
      state.showCompletedStops = action.payload;
    },
    setSession: (state, action: PayloadAction<string>) => {
      state.currentSessionId = action.payload;
    },
    updateSession: (state, action: PayloadAction<Session>) => {
      const session = action.payload;
      state.sessions[session.id] = session;
      state.attachments = {
        ...state.attachments,
        ...keyBy(session.attachments, "fileId"),
      };
      state.attachmentsByCargoId = mapValues(
        groupBy(values(state.attachments), "cargoId"),
        (attachments) => attachments.map((a) => a.fileId)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSession.fulfilled, (state, action) => {
        const { stops, cargos, clients, routeStops, createdByDispatcher } =
          action.payload;

        state.sessionStops[action.meta.arg] = {
          stopIds: stops.map((x) => x.id),
          routeStops: routeStops,
        };
        state.stops = { ...state.stops, ...keyBy(stops, "id") };
        state.routeStops = routeStops;
        state.cargos = keyBy(cargos, "id");
        state.clients = keyBy(clients, "id");
        state.createdByDispatcher = createdByDispatcher;
        // state.trailerId = action.payload.trailerId;
      })
      .addCase(loadOtherSessions.fulfilled, (state, action) => {
        state.sessions = keyBy(action.payload, "id");
        state.attachments = {
          ...state.attachments,
          ...keyBy(
            action.payload.flatMap((x) => x.attachments),
            "fileId"
          ),
        };
        state.attachmentsByCargoId = mapValues(
          groupBy(values(state.attachments), "cargoId"),
          (attachments) => attachments.map((a) => a.fileId)
        );
      })
      .addCase(completeStop.fulfilled, (state, action) => {
        const session = action.payload;
        state.sessions[session.id] = session;
      })
      .addCase(loadStopDocuments.pending, (state, action) => {
        const { stopId } = action.meta.arg;
        state.stopsDocuments[stopId] = [];
      })
      .addCase(loadStopDocuments.fulfilled, (state, action) => {
        const { stopId } = action.meta.arg;
        state.stopsDocuments[stopId] = action.payload;
      });
  },
});

export default slice.reducer;
export const { setSession, setShowCompletedStops } = slice.actions;
